import axios from 'axios';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';
import { validationMessage } from 'vuelidate-messages';
import Vue from 'vue';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import VueRecaptcha from 'vue-recaptcha';

type SimulacaoDto = {
    imovel_id: number;
    faixa_etaria_id: number;
    nome: string;
    telefone: string;
    email: string;
    grecaptcha: string;
};

interface Lead {
    nome?: string;
    email?: string;
    telefone?: string;
    telefone_ddi?: string;
    idsituacao?: number;
    idempreendimento?: number;
    origem: string;
    grecaptcha?: string;
    campos_adicionais?: {
        [key: string]: string;
    };
    interacoes?: {
        tipo: string;
        descricao: string;
    }[];
}

const API_URL = process.env.MIX_API_CVCRM_URL;

const TOKEN = process.env.MIX_TOKEN_CVCRM;

function initSimulacao() {
    const simulacaoForm = document.querySelector<HTMLElement>('.simulacao__form');
    if (simulacaoForm === null) {
        return;
    }
    Vue.use(Vuelidate);
    Vue.use(VueMask);

    let formMessages = {
        required: () => 'O campo é obrigatório',
        maxLength: ({ $params }: any) => `O campo deve possuir no máximo ${$params.maxLength.min} caracteres.`,
        email: () => 'O e-mail inserido é inválido',
        sameAs: () => 'Você deve aceitar as políticas de privacidade.',
    };
    let validationRules = {
        imovel_id: {
            required,
        },
        faixa_etaria_id: {
            required,
        },
        nome: {
            required,
            maxLength: maxLength(255),
        },
        telefone: {
            required,
            maxLength: maxLength(15),
        },
        email: {
            required,
            email,
        },
        grecaptcha: {
            required,
        },
    };
    const getMask = (value: string) => {
        return value.length < 15 ? '(##) ####-####?#' : '(##) #####-####';
    };

    new Vue({
        el: simulacaoForm,
        data: {
            imovel_id: null,
            faixa_etaria_id: null,
            nome: '',
            telefone: '',
            email: '',
            grecaptcha: null,

            loading: false,
            phoneMask: '(##) ####-####?#',
        },
        components: {
            VueRecaptcha,
        },
        validations: validationRules,
        watch: {
            telefone(value) {
                this.phoneMask = getMask(value);
            },
        },
        methods: {
            validationMsg: validationMessage(formMessages, {}),
            reset() {
                this.imovel_id = null;
                this.faixa_etaria_id = null;
                this.nome = '';
                this.telefone = '';
                this.email = '';
                this.grecaptcha = null;
                this.$refs.grecaptcha.reset();
                this.$v.$reset();
            },
            findEmpreedimento(empreendimentos): number {
                const options = document.getElementById('imovel_id');

                const childOptions = options?.childNodes;

                const childOptionsFromArr = Array.prototype.slice.call(childOptions);

                const selectedOption = childOptionsFromArr.find((option) => option.selected) as HTMLOptionElement;

                const nameOption = selectedOption?.innerText.trim();

                const empreendimento = empreendimentos.find((empreendimento) => empreendimento.nome.toLowerCase() === nameOption.toLowerCase());

                return empreendimento ? empreendimento.idempreendimento : 0;
            },
            async getListEmpreendimentosCVCRM() {
                try {
                    const response = await axios.get('https://canopus.cvcrm.com.br/api/v1/cvbot/empreendimentos', {
                        headers: {
                            TOKEN: TOKEN as string,
                            email: 'suporte@maximize.com.br',
                            'Content-Type': 'application/json',
                        },
                    });

                    return response.data;
                } catch (error) {
                    console.error('Erro ao buscar empreendimentos:', error);

                    return [];
                }
            },
            treatCityString(): string {
                const city = localStorage.getItem('cidade');

                const treatedCity = city?.replace(/-/g, ' ').toUpperCase()!;

                if (treatedCity === 'SAO LUIS') {
                    return 'SÃO LUÍS';
                }

                return treatedCity;
            },
            async postLead(lead: Lead): Promise<void> {
                const listEmpreendimentos = await this.getListEmpreendimentosCVCRM();

                const idEmpreendimento = this.findEmpreedimento(listEmpreendimentos);
                const city = this.treatCityString();
                const origem = 'SI';

                lead.idempreendimento = idEmpreendimento;
                lead.campos_adicionais = {
                    Loja: city,
                };
                lead.origem = origem;

                delete lead.grecaptcha;

                try {
                    await axios.post(API_URL as string, lead, {
                        headers: {
                            TOKEN: TOKEN as string,
                            'Content-Type': 'application/json',
                        },
                    });
                } catch (error) {
                    console.error('Erro ao cadastrar lead:', error);
                }
            },
            getFormData(): SimulacaoDto {
                return {
                    imovel_id: this.imovel_id,
                    faixa_etaria_id: this.faixa_etaria_id,
                    nome: this.nome,
                    telefone: this.telefone.replaceAll(/[^0-9]/g, ''),
                    email: this.email,
                    grecaptcha: this.grecaptcha,
                };
            },
            warnSubmitSuccess(): void {
                Swal.fire({
                    title: 'Sucesso!',
                    text: 'Sua simulação foi requisitada com sucesso.',
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'Obrigado(a)!',
                    customClass: {
                        popup: 'modal__content--sucesso',
                        header: 'modal__header modal__header--sucesso',
                        title: 'modal__title--sucesso',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__sucesso',
                    },
                    buttonsStyling: false,
                });
            },
            warnSubmitFailure(): void {
                Swal.fire({
                    title: 'Ops! Houve um problema',
                    text: 'No momento não foi possível enviar sua requisição de simulação, tente novamente mais tarde!',
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false,
                });
            },
            startLoading(): void {
                this.loading = true;
            },
            stopLoading(): void {
                this.loading = false;
            },
            formDataIsValid(): boolean {
                this.$v.$touch();
                return !this.$v.$invalid;
            },
            submitForm(): void {
                this.startLoading();

                const data = this.getFormData();

                if (this.formDataIsValid() === false) {
                    console.log(this.$v);
                } else {
                    axios
                        .post(/* url */ '/api/simulacoes', /* data */ this.getFormData())
                        .then(this.warnSubmitSuccess)
                        .then(this.reset)
                        .then(this.postLead(data))
                        .catch(this.warnSubmitFailure)
                        .finally(() => {
                            this.stopLoading();
                        });
                }
                this.stopLoading();
            },
            setGrecaptchaToken(grecaptchaToken: string): void {
                this.grecaptcha = grecaptchaToken;
            },
            warnRecaptchaExpiral(): void {
                Swal.fire({
                    title: 'Algo deu errado',
                    text: 'Sua verificação de Recaptcha expirou, tente novamente.',
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false,
                });
            },
            warnRecaptchaError(): void {
                Swal.fire({
                    title: 'Ops! Houve um problema',
                    text: 'A verificação do seu Recaptcha falhou!',
                    showCancelButton: false,
                    confirmButtonColor: '#D0782F',
                    confirmButtonText: 'OK',
                    customClass: {
                        popup: 'modal__content--erro',
                        header: 'modal__header modal__header--erro',
                        title: 'modal__title--erro',
                        actions: 'modal__actions',
                        confirmButton: 'modal__btn modal__erro',
                    },
                    buttonsStyling: false,
                });
            },
        },
    });
}
initSimulacao();
